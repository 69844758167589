import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";

const BusinessMentoringBrightonPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "jason-client-meeting-5.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				james: file(relativePath: { eq: "testimonials/james-gasson.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Business Mentoring in Brighton";
	const description = "";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const james = data.james.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url:`${siteUrl}`,
					id:`${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business Mentoring",
				item: {
					url:`${siteUrl}/business-mentoring`,
					id:`${siteUrl}/business-mentoring`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Brighton",
				item: {
					url:`${siteUrl}/business-mentoring/brighton`,
					id:`${siteUrl}/business-mentoring/brighton`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/business-mentoring/brighton`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business Mentoring in Brighton",
						},
					],
				}}
			/>

			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Business Mentor Brighton"
				description1="Business mentoring for self-employed small business owners in Brighton"
				description2=""
			/>

			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Business mentoring for self-employed small business owners in
								Brighton
							</h2>
							<p>Are you looking for business mentoring in Brighton?</p>
							<p>
								I’m Jason Cornes, and as a business mentor, I can help you to:
							</p>
							<ul className="pl-4">
								<li>Enjoy your work more and boost your self-confidence</li>
								<li>Improve your focus and business skills</li>
								<li>Boost your businesses profitability</li>
								<li>Scale or grow your business</li>
								<li>And see more cash in the bank.</li>
							</ul>
							<p>
								Right now, you may know more than anyone what issues are holding
								your business back from being the success you know it can be. Or
								on the other hand, perhaps you’re not sure and need some help to
								know what to focus on.
							</p>
							<p>
								Whether these are emotional limitations which are impacting your
								motivation, or self-confidence, causing you to be fearful, or
								practical issues with the way you sell your service or operate.
							</p>
							<p>
								Either way, you know that you have two choices, tackle these
								issues head-on, or struggle through another year unhappy with
								the level of progress you're making.
							</p>
							<p>
								I’ve mentored numerous self-employed small businesses owners
								across Brighton to do just this, helping them to identify their
								vision and develop an action-packed plan to create the success
								they deserve.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5">
				<Container>
					<Row>
						<Col>
							<h2 className="py-3">
								Brighton - Home to an eclectic mix of businesses
							</h2>
							<p>
								Brighton and Hove is home to a rich mix of residents, cultures
								and businesses. It’s why so many flock to our city each year,
								and it may be why you chose to start your business here.
							</p>
							<p>
								Whether it’s the Pier, our art...or even the fact that ABBA won
								Eurovision at the Brighton Dome, just like our great city, as
								small business owners, we all have our strengths and core
								attractions.
							</p>
							<p>
								But on the flip side, we also have issues that, perhaps on the
								outside, aren’t seen by all. But yet hold us back and hinder our
								development nonetheless. My job as a business mentor is to help
								business owners like you here in Brighton to overcome your own
								unique struggles.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">To act as your hidden business partner.</h2>
							<p>
								Like any good business partner, my job isn’t to tell you what to
								do. Instead, I remain in your corner, listening, observing and
								offering unbiased, experienced hints, tips, suggestions and
								advice on the myriad of decisions you make on your business
								journey.
							</p>
							<p>
								Whether this coaching is delivered remotely or face-to-face,
								business mentoring will cover:
							</p>
							<ul className="pl-4">
								<li>Listening</li>
								<li>Getting strategic</li>
								<li>Changing your reality</li>
								<li>The money thing</li>
								<li>Agreed action points and accountability</li>
							</ul>
							<p>
								A business strategy/MOT focus will create action plans covering:
							</p>
							<ul className="pl-4">
								<li>Marketing</li>
								<li>Sales</li>
								<li>Financia</li>
								<li>Operational</li>
							</ul>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">A local case study from here in Brighton</h2>
							<p>
								Take James from Third Circle Recordings who is a skilled and
								talented musician and recording engineer here in Brighton. We
								are working together to take his career and business to the next
								level.
							</p>
							<p>
								Although like so many businesses trading has been affected by
								lockdown, James took the opportunity to be proactive during this
								‘quiet time’ and work with me to put a vision in place and a
								plan as to how we can make that vision a reality. Working with
								James is both interesting and fun and we are improving and
								enhancing every aspect of his business including developing some
								exciting new products and services and putting a marketing plan
								into action.
							</p>
							<p>
								James empathises with the struggles of many self-employed people
								in his Testimonial below.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Local testimonial:</h3>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="James Gasson. Third Circle Recordings"
						review="It seems to me that, for many people, working for yourself can be
          lonely, confusing and frustrating. After all, we’re good at doing the
          thing we’re good at, but not necessarily at knowing how to formulate
          that into a workable, competitive business. I was stuck in this
          position for many years, not quite confident enough to put my foot
          down properly, and never sure of the destination were I to do so.
          Working with Jason has begun to change all that. He has offered
          direction, focus, and an endless well of positivity, things that have
          been sorely lacking during my years of struggle and self doubt. His
          guidance has given me a level of confidence that helps me to stay
          motivated, even in the face of challenges under which I would
          otherwise have surely felt defeated, and for that I am extremely
          grateful. I highly recommend Jason if, like me, you are feeling
          determined but lost. I look upon our journey with great excitement and
          readiness, and can’t wait to see where it leads. Five stars indeed"
						linkedIn="https://www.linkedin.com/in/jamesgasson/"
						profilePic={james}
					/>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Ready to take your Brighton business to the next level?
							</h2>
							<p>
								I have coached small business owners here in Brighton from
								various backgrounds and industries, from builders to dog
								groomers, musicians and artists to tradespeople, accountants to
								consultants and coaches to university academics - and I’d love
								to add you to this list.
							</p>
							<p>
								Whether you wish to meet locally (I live along the coast near
								Eastbourne) for face-to-face mentoring or would instead rather
								we speak remotely via Zoom or Skype - it’s entirely up to you.
							</p>
							<p>
								If you feel there are areas of your business that could benefit
								from some unbiased business mentoring, let’s chat. Click below
								to book an introductory call with me.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Business Mentoring in Brighton"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default BusinessMentoringBrightonPage;
